import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Stack,
  Sticky,
  Modal,
  CloseIcon,
  OpenInNewIcon,
  Tabs,
} from "@operata/adagio";
import { ButtonRefactored as Button } from "@operata/adagio";
import "./style.scss";
import CallHeader from "./CallHeader";
import CallDetail from "./CallDetail";
import TabCharts from "./Tabs/_TabCharts";
import TabSummary from "./Tabs/_TabSummary";
import TabLogs from "./Tabs/_TabLogs";
import TabRTC from "./Tabs/_TabRTC";
import PropTypes from "prop-types";
import { auth, USER_ROLE_SUPER } from "../../auth/Auth";
import { getCallData } from "./tools";
import TabTelemetry from "./Tabs/_TabTelemetry";

class CallOnPageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: props?.contactId || null,
      call: null,
      isModalOpen: true,
      hasResolved: false,
      playbookId: null,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { contactId } = this.state;

    const url = window.location.href;
    const urlSplit = url.split("/");

    const playbookIndex = urlSplit.indexOf("playbooks");
    const playbookId = playbookIndex !== -1 ? urlSplit[playbookIndex + 1] : null;

    if (contactId === null) {
      contactId = urlSplit[urlSplit.length - 1].split("?")[0];
      this.setState({ contactId });
    }

    this.setState({ playbookId });

    const region = auth.getCurrentRegion();
    getCallData(
        contactId,
        region,
        auth.hasPermission([USER_ROLE_SUPER])
    ).then((call) => {
      this.setState({ call, hasResolved: true });
    });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
    const newUrl = `${this.props.match.url}/${this.state.playbookId ? `${this.state.playbookId}` : ""}`;
    this.props.history.push(newUrl);
    this.props.onClose();
  }

  render() {
    const { call, contactId, hasResolved } = this.state;
    const basePath = `${this.props.match.url}/${this.state.playbookId ? `${this.state.playbookId}/` : ""}call/${contactId}`;

    return (
      <>
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={() => {
            this.closeModal();
          }}
          width="max"
        >
          <CallHeader
            call={call}
            hasResolved={hasResolved}
            actions={
              <Stack direction="row" gap="4">
                <Button
                  size="medium"
                  type="tertiary"
                  onClick={() => {
                    window
                      .open(
                        "/" + auth.getCurrentGroupId() + "/call/" + contactId,
                        "_blank"
                      )
                      .focus();
                  }}
                  icon={<OpenInNewIcon />}
                >
                  Open in new Tab
                </Button>
                <Button
                  size="medium"
                  type="ghost"
                  icon={<CloseIcon />}
                  onClick={() => {
                    this.closeModal();
                  }}
                  tooltip={{
                    label: "Close",
                    position: "right",
                  }}
                />
              </Stack>
            }
          />

          <Modal.Content>
            <Stack padding="medium">
              <CallDetail call={call} hasResolved={hasResolved} />
            </Stack>

            <Sticky top={0} zindex={5}>
              <Tabs padding="medium" background="solid">
                <Tabs.Tab exact to={`${basePath}`} label="Charts" />
                <Tabs.Tab to={`${basePath}/summary`} label="Summary" />
                <Tabs.Tab to={`${basePath}/rtc`} label="WebRTC" />
                <Tabs.Tab to={`${basePath}/logs`} label="Softphone Logs" />
                <Tabs.Tab to={`${basePath}/telemetry`} label="Telemetry" />
              </Tabs>
            </Sticky>

            <Switch>
              <Route exact path={basePath}>
                <TabCharts call={call} hasResolved={hasResolved} />
              </Route>
              <Route path={`${basePath}/summary`}>
                <TabSummary call={call} hasResolved={hasResolved} />
              </Route>
              <Route path={`${basePath}/rtc`}>
                <TabRTC call={call} hasResolved={hasResolved} />
              </Route>
              <Route path={`${basePath}/logs`}>
                <TabLogs call={call} hasResolved={hasResolved} />
              </Route>
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Route path={`${basePath}/telemetry`}>
                  <TabTelemetry call={call} hasResolved={hasResolved} />
                </Route>
              )}
            </Switch>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

CallOnPageModal.propTypes = {
  call: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  onClose: PropTypes.func,
  contactId: PropTypes.string,
};

export default CallOnPageModal;
